import React, { Fragment, useState, useEffect } from "react";
import Sidebar from "../layout/Sidebar";
import { useHistory } from "react-router-dom";
import {
  PORTFOLIO_INPUTS_CREATE_ROUTE,
  INITIAL_ROUTE,
} from "../common/RoutePaths";
import API from "../redux/API";
import {
  Container,
  Card,
  CardTitle,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  CardBody,
  FormFeedback,
} from "reactstrap";
import { MdArrowBack } from "react-icons/md";
import { authHeader, getRmID } from "../common/Mainfunctions";
import Swal from "sweetalert2";
import { CREATE_CLIENT } from "../common/urls";
import * as Yup from "yup";
import { CLIENT_LISTING } from "../redux/actions/ActionTypes";
import { useFormik } from "formik";
import { EMAIL_REGEX } from "../constants/validation";
import { getAlert, getAlertToast } from "../common/Mainfunctions";
import CustomLoader from "../common/CustomLoader";
import {
  ASSIGN_IC,
  USER_LIST_URL,
  RM_LIST,
  IC_LIST,
  PERMISSION,
  ARM_LIST_URL,
  RM_USER_URL,
  RM_LIST_URL,
  RM_EDIT_URL,
  RM_ARM_LIST_URL,
} from "../common/urls";
import PhoneInput from "react-phone-input-2";
import { useDispatch } from "react-redux";
import Select from "react-select";
import "react-phone-input-2/lib/style.css";

export default function AddClient(props) {
  const {
    userId,
  } = props;
  const history = useHistory();
  const [clientNameList, setClientNameList] = useState([]);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [permission, setpermission] = useState("");
  const [Value, setValue] = useState("");
  const [roleSelect, setRoleSelect] = useState("");
  const [search, setSearch] = useState("");
  const [AdminValue, setAdminValue] = useState("");
  const [SuperValue, setSuperValue] = useState("");
  const [rmValue, setrmValue] = useState("");
  const [ICvalue, setICvalue] = useState("");
  const [clientOptions, setClientOptions] = useState([]);
  const [rmuserList, setrmuserList] = useState([]);
  const [role,setRole]=useState("");
  const [armList, setarmList] = useState([]);
  const [rmList, setrmList] = useState([]);
  const [adminList, setadminList] = useState([]);
  const [rowperpage, setRowPerPage] = useState(100);
  const [currentpage, setCurrentPage] = useState(0);
  const [clientLoading, setClientLoading] = useState(false);
  const [RMlist, setRMList] = useState([]);
  const [contactNumber, setContactNumber] = useState("");
  const validationSchema = Yup.object({
    client_firstname: Yup.string().trim().required("First name is required"),
    client_contactno: Yup.string()
      .trim()
      .required("Contact number is required"),
    client_email: Yup.string()
      .trim()
      .required("email is required")
      .matches(EMAIL_REGEX, "Invalid email"),
  });
  const phoneNumberChange = (value, e) => {
    setContactNumber(value);
    let { dialCode } = e;
    const phone_array = value.split(dialCode);
    e.target = { name: "", value: "" };
    if (phone_array.length == 2) {
      e.target.name = "client_contactno";
      e.target.value = phone_array[1];
      handleChange(e);
    }
    e.target.name = "client_countrycode";
    e.target.value = dialCode;
    handleChange(e);
  };
  const getRmList = async (search) => {
    setLoading(true);
    const config = {
      method: "GET",
      url: IC_LIST,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      setRMList(data);
      console.log(data, "pppppppppppppppppppppppppppppp");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getRmList();
  }, []);
  const getRMUSERList = async (search) => {
    setLoading(true);

    const config = {
      method: "GET",
      url: RM_LIST_URL,
      headers: authHeader(),
      params: {
        index: currentpage,
        count: rowperpage,
        searchterm: search,
      },
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      dispatch({
        type: CLIENT_LISTING,
        payload: data,
      });
      let ARMList = data.map((e, i) => ({
        label: e.email,
        value: e.id,
      }));
      setadminList(ARMList);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getRMUSERList();
  }, []);
  const getRMClientList = async (search) => {
    setLoading(true);

    const config = {
      method: "GET",
      url: RM_USER_URL + getRmID(),
      headers: authHeader(),
      params: {
        index: currentpage,
        count: rowperpage,
        searchterm: search,
      },
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      setrmuserList(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getRMClientList();
  }, [getRmID]);
  const getpermission = async () => {
    const config = {
      method: "GET",
      url: PERMISSION + getRmID(),
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      setpermission(response.data.data);
      setRole(response.data.data.user_permission);
      console.log(response.data.data, "response.data.dataresponse.data.data");
      //Swal.fire(getAlertToast("Success", "User shared Successfully"));
    } catch (error) {
      let { message = "" } = error;
      console.log(message);
    } finally {
      //setLoading(false);
    }
  };
  useEffect(() => {
    getpermission();
  }, [getRmID]);

  const getClientNameList = async () => {
    const config = {
      method: "GET",
      url: USER_LIST_URL,
      headers: authHeader(),
      params: {
        searchterm: search,
        role: "rm",
      },
    };
    try {
      setLoading(true);
      const response = await API(config);
      const { data = [] } = response.data;
      setClientNameList(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getClientNameList();
  }, []);

  const getARMClientList = async (search) => {
    setLoading(true);

    const config = {
      method: "GET",
      url: ARM_LIST_URL + getRmID(),
      headers: authHeader(),
      params: {
        index: currentpage,
        count: rowperpage,
        searchterm: search,
      },
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      setarmList(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getARMClientList();
  }, [getRmID]);
  console.log(Value, "client_arm_idclient_arm_idclient_arm_idclient_arm_id");
  const saveFormValues = async (values) => {
    setLoading(true);
    // if(permission.user_permission=="arm"){
    // values.client_rm_id=Value;
    // };
    // if(permission.user_permission=="ic"){
    // values.client_ic_id=ICvalue;
    // values.client_rm_id=getRmID();
    // }
    // if(permission.user_permission=="rm"){
    // values.client_arm_id= rmValue;
    // values.client_rm_id=getRmID();
    // }
    // if(permission.user_permission=="admin"){
    //   values.client_user_id= AdminValue;
    //   values.client_rm_id=getRmID();
    //   }

    values.client_user_id = Value;
    values.client_rm_id = getRmID();
    values.client_ic_id = ICvalue;

    const config = {
      method: "POST",
      url: CREATE_CLIENT,
      headers: authHeader(),
      data: values,
    };
    try {
      const response = await API(config);
      const { status, data = {}, message } = response.data;
      const { client_id } = data;
      if (status === "Success") {
        localStorage.setItem("SELECTED_CLIENT_ID", client_id);
        Swal.fire(getAlertToast("Success", "Client created Successfully"));
        history.push(INITIAL_ROUTE);
      } else {
        Swal.fire(getAlert("error", message));
      }
    } catch (error) {
      let errorObj = Object.assign({}, error);
      let errors = errorObj.response.data.errors;
      let { client_email = "" } = errors;
      if (client_email) {
        Swal.fire(getAlert("error", client_email[0]));
      } else {
        Swal.fire(getAlert("error", error));
      }
    } finally {
      setLoading(false);
    }
  };

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      client_firstname: "",
      client_user_id: getRmID(),
      client_email: "",
      client_contactno: "",
      client_countrycode: "",
    },
    validationSchema,
    onSubmit: (values) => {
      saveFormValues(values);
    },
  });

  useEffect(()=>{
    if(role=="rm"){
      setRoleSelect("arm")
    }
    if(role=="arm"){
      setRoleSelect("rm")
    }
    if(role=="admin"){
      setRoleSelect("admin")
    }
    if(role=="super_admin"){
      setRoleSelect("super_admin")
    }
    if(role=="ic"){
      setRoleSelect("ic")
    }
  },[role,roleSelect]);

  const getRM_ARM_NameList = async (search) => {
    const config = {
      method: "GET",
      url: RM_ARM_LIST_URL,
      headers: authHeader(),
      params: {
        role: roleSelect,
        index: currentpage,
        count: rowperpage,
        searchterm: search,
      },
    };
    try {
      setClientLoading(true);
      const response = await API(config);
      const { data = [] } = response.data;
      let clientList = data.map((e, i) => ({
        label: e.email,
        value: e.id,
      }));
    } catch (error) {
      console.error(error);
    } finally {
      setClientLoading(false);
    }
  };

  const RMDetails = async () => {
    const config = {
      method: "GET",
      url: RM_EDIT_URL + getRmID(),
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data } = response.data;
      const { client = [] } = data;
      let selclient = [];
      data.arm.map((record) => {
        selclient.push({ label: record.email, value: record.id });
      });
      setClientOptions(selclient);
      // setClient(selclient);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(()=>{
    RMDetails();
  },[]);

  useEffect(()=>{
    getRM_ARM_NameList();
  },[roleSelect]);

console.log(userId,'cccccccccccccccccccccccccccccc')
  return (
    <div className="bg-white">
      <Fragment>
        <div className="sidebarCont bg_26">
          <Sidebar />
        </div>
        <div className="contentWrapper">
          <div className="bg_d6 p-3">
            <div className="row">
              <div class="col-sm-8">
                <h3 className="text_16_c4">Add New Client</h3>
              </div>
              <div className="text-right col-sm-4" onClick={() => history.goBack()}>
                <a className="text_16_c4">
                  <MdArrowBack ></MdArrowBack> back
                </a>
              </div>
            </div>
          </div>
          <Container fluid>
            <div className="min-screenheight mt-3">
              <CardBody>
                <Row>
                  <Col sm="6" className="align-item-center">
                    <Form
                      form="true"
                      onSubmit={handleSubmit}
                      className="mt-3 form_space"
                    >
                      <FormGroup>
                        <Input
                          type="text"
                          name="client_firstname"
                          id="client_firstname"
                          placeholder="Enter client name"
                          invalid={
                            errors.client_firstname && touched.client_firstname
                              ? true
                              : false
                          }
                          onChange={handleChange}
                          value={values.client_firstname}
                        />
                        <FormFeedback>
                          {errors.client_firstname && touched.client_firstname
                            ? errors.client_firstname
                            : ""}
                        </FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <Input
                          type="client_email"
                          name="client_email"
                          id="client_email"
                          placeholder="Client email id"
                          invalid={
                            errors.client_email && touched.client_email
                              ? true
                              : false
                          }
                          onChange={handleChange}
                          values={values.client_email}
                        />
                        <FormFeedback>
                          {errors.client_email && touched.client_email
                            ? errors.client_email
                            : ""}
                        </FormFeedback>
                      </FormGroup>

                      <FormGroup>
                        <PhoneInput
                          name="client_contactno"
                          id="client_contactno"
                          country={"sg"}
                          onChange={phoneNumberChange}
                          value={contactNumber}
                        />

                        <FormFeedback
                          style={{
                            display:
                              errors.client_contactno &&
                              touched.client_contactno
                                ? "block"
                                : "none",
                          }}
                        >
                          {errors.client_contactno && touched.client_contactno
                            ? errors.client_contactno
                            : ""}
                        </FormFeedback>
                      </FormGroup>
                      <FormGroup>
                        <Input
                          type="text"
                          name="text"
                          id="text"
                          placeholder="Enter tags"
                        />
                      </FormGroup>
                      <FormGroup>
                        <label for="exampleFormControlSelect1">
                          Assign Investment Consultant (Optional)ddddddd
                        </label>
                        <select
                          class="form-control"
                          id="exampleFormControlSelect1"
                          onChange={(e) => {
                            setICvalue(e.target.value);
                          }}
                        >
                          <option>Choose investment consultant </option>
                          {RMlist?.map((rm) => {
                            return <option value={rm.id}>{rm.email}</option>;
                          })}
                        </select>
                      </FormGroup>
                      <FormGroup>
                        {permission.user_permission == "arm" &&
                             <label for="exampleFormControlSelect2">
                                RM User
                              </label>
                              }
                              {permission.user_permission == "rm" &&
                             <label for="exampleFormControlSelect2">
                                ARM User
                              </label>
                              }
                        {console.log(rmList, "RMlistRMlistRMlistRMlist")}
                        <Select
                                class="form-control"
                                id="exampleFormControlSelect2"
                                options={clientOptions}
                                value={Value}
                                onChange={(e)=>{ 
                                  setValue(e);
                                  //handleChange(e)
                                }}
                              >
                          <option>Choose</option>
                          {rmuserList?.map((eve) => {
                            return (
                              <option value={eve.user_id}>
                                {eve.user_name}
                              </option>
                            );
                          })}
                        
                        </Select>
                      </FormGroup>

                      {/* <form>
                      <label className="d-block">Preferred Communication</label>
                      <div className="form-group checked d-inline-block">
                        <input type="checkbox" id="html"/>
                        <label className="bg_email" for="html">Email</label>
                      </div>
                      <div className="form-group checked d-inline-block">
                        <input type="checkbox" id="css"/>
                        <label className="bg_email ml-3" for="css">WhatsApp</label>
                      </div>
                    </form> */}
                      <Button
                        type="submit"
                        className="d-block pl-5 pr-5 w-100 pt-2 pb-2 "
                        color="primary"
                      >
                        {loading && <CustomLoader loading={loading} />}
                        Next
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
            </div>
          </Container>
        </div>
      </Fragment>
    </div>
  );
}
