import React, { Fragment, useEffect, useState } from "react";
import Sidebar from "../layout/Sidebar";
import { useHistory } from "react-router-dom";
import {
  PORTFOLIO_INPUTS_CREATE_ROUTE,
  INITIAL_ROUTE,
} from "../common/RoutePaths";
import API from "../redux/API";
import {
  Container,
  Card,
  CardTitle,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  Row,
  Col,
  CardBody,
  FormFeedback,
} from "reactstrap";
import { MdArrowBack } from "react-icons/md";
import { authHeader, getRmID } from "../common/Mainfunctions";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import { RM_ARM_LIST_URL,CREATE_CLIENT, CLIENT_LIST, SEARCH_CLIENT_LIST,RM_LIST_URL,RM_USER_URL,ARM_LIST_URL,PERMISSION } from "../common/urls";
import * as Yup from "yup";
import { useFormik } from "formik";
import { EMAIL_REGEX } from "../constants/validation";
import { getAlert, getAlertToast } from "../common/Mainfunctions";
import CustomLoader from "../common/CustomLoader";
import { ASSIGN_IC, USER_LIST_URL, RM_LIST, IC_LIST } from "../common/urls";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { TiTick } from "react-icons/ti";
import Select from "react-select";
import { CLIENT_LISTING } from "../redux/actions/ActionTypes";
import { Link } from "react-router-dom";
import { FaArrowRight } from "react-icons/fa";

export default function OnBoarding(props) {
  const dispatch = useDispatch();
  const [selected, setselected] = useState("1");
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [contactNumber, setContactNumber] = useState("");
  const [list, setList] = useState([]);
  const [adminList, setadminList] = useState([]);
  const [searchListuser, setsearchListuser] = useState([]);
  const [armList,setarmList]=useState([]);
  const [clientNameList, setClientNameList] = useState([]);
  const [RMlist, setRMList] = useState([]);
  const [rmuserList,setrmuserList]= useState([]);
  const [search, setSearch] = useState("");
  const [rowperpage, setRowPerPage] = useState(100);
  const [inputlist, setinputlist] = React.useState("");
  const [AdminValue,setAdminValue]=useState("");
  const [SuperValue,setSuperValue]=useState("");
  const [rmValue,setrmValue]=useState("");
  const [RMfromRmList,setRMfromRmList]=useState([]);
  const [ICvalue,setICvalue]=useState("");
  const [Value,setValue]=useState("");
  const [currentpage, setCurrentPage] = useState(0);
  const [opt, setopt] = React.useState("");
  const [roleSelect, setRoleSelect] = useState("");
  const [clientOptions, setClientOptions] = useState([]);
  const [userList, setUserList] = useState("");
  const [role,setRole]=useState("");
  const [clientLoading, setClientLoading] = useState(false);
  const [permission, setpermission] = useState("");
  const [message, setMessage] = useState("");
  const validationSchema = Yup.object({
    client_firstname: Yup.string().trim().required("First name is required"),
    client_contactno: Yup.string()
      .trim()
      .required("Contact number is required"),
    client_email: Yup.string()
      .trim()
      .required("email is required")
      .matches(EMAIL_REGEX, "Invalid email"),
  });
  // useEffect(()=>{
  //   error={setMessage}
  //   console.log(errors,'4444444444444444')
  // })
  const phoneNumberChange = (value, e) => {
    setContactNumber(value);
    let { dialCode } = e;
    const phone_array = value.split(dialCode);
    e.target = { name: "", value: "" };
    if (phone_array.length == 2) {
      e.target.name = "client_contactno";
      e.target.value = phone_array[1];
      handleChange(e);
    }
    e.target.name = "client_countrycode";
    e.target.value = dialCode;
    handleChange(e);
  };

  const saveFormValues = async (values) => {
    setLoading(true);
    // if(permission.user_permission=="arm"){
    //   values.client_rm_id=Value;
    //   };
    //   if(permission.user_permission=="ic"){
    //   values.client_ic_id=ICvalue;
    //   values.client_rm_id=getRmID();
    //   }
    //   if(permission.user_permission=="rm"){
    //   values.client_arm_id= rmValue;
    //   values.client_rm_id=getRmID();
    //   }
    //   if(permission.user_permission=="admin"){
    //     values.client_user_id= AdminValue;
    //     values.client_rm_id=getRmID();
    //     }
          values.client_user_id= Value;
          values.client_rm_id=getRmID();
          values.client_ic_id=ICvalue;
    const config = {
      method: "POST",
      url: CREATE_CLIENT,
      headers: authHeader(),
      data: values,
    };
    try {
      const response = await API(config);
      const { status, data = {}, message } = response.data;
      const { client_id } = data;
      if (status === "Success") {
        localStorage.setItem("SELECTED_CLIENT_ID", client_id);
        Swal.fire(getAlertToast("Success", "Client created Successfully"));
        // history.push(INITIAL_ROUTE);
        history.push(`/List-portfolio/${client_id}`);
      } else {
        Swal.fire(getAlert("error", message));
      }
    } catch (error) {
      let errorObj = Object.assign({}, error);
      let errors = errorObj;

      //let errors = errorObj.response.data.errors;
      let { client_email = "" } = error.errors;
      if (client_email) {
        Swal.fire(getAlert("error", client_email[0]));
      } else {
        Swal.fire(getAlert("error", error));
      }
    } finally {
      setLoading(false);
    }
  };
  const getpermission = async () => {
    const config = {
      method: "GET",
      url: PERMISSION + getRmID(),
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      setpermission(response.data.data);
      setRole(response.data.data.user_permission);
          console.log(response.data.data.user_permission,'  ')
      //Swal.fire(getAlertToast("Success", "User shared Successfully"));
    } catch (error) {
      let { message = "" } = error;
      console.log(message);
    } finally {
      //setLoading(false);
    }
  };
  useEffect(() => {
    getpermission();
  }, [getRmID,role]);


  const getRMClientList = async (search) => {
    setLoading(true);
    const config = {
      method: "GET",
      url: RM_USER_URL+ getRmID(),
      headers: authHeader(),
      params: {
        index: currentpage,
        count: rowperpage,
        searchterm: search,
      },
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      // if(permission.user_permission=="arm"){
      //   setRMfromRmList(data);
      // }
      // if(permission.user_permission=="admin" || "super_admin"){
      setrmuserList(data);
      //}
      console.log(data,'sssssssssssssssss')
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(()=>{
    getRMClientList();
  },[getRmID]);
  
  // const getARMClientList = async (search) => {
  //   setLoading(true);

  //   const config = {
  //     method: "GET",
  //     url: ARM_LIST_URL+ getRmID(),
  //     headers: authHeader(),
  //     params: {
  //       index: currentpage,
  //       count: rowperpage,
  //       searchterm: search,
  //     },
  //   };
  //   try {
  //     const response = await API(config);
  //     const { data = [], totalrecords } = response.data;
  //     setarmList(data);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // useEffect(()=>{
  //   getARMClientList();
  // },[getRmID]);
console.log(SuperValue,'client_user_id =  SuperValue;')
  

  // const getRMUSERList = async (search) => {
  //   setLoading(true);

  //   const config = {
  //     method: "GET",
  //     url: RM_LIST_URL,
  //     headers: authHeader(),
  //     params: {
  //       index: currentpage,
  //       count: rowperpage,
  //       searchterm: search,
  //     },
  //   };
  //   try {
  //     const response = await API(config);
  //     const { data = [], totalrecords } = response.data;
  //     dispatch({
  //       type: CLIENT_LISTING,
  //       payload: data,
  //     });
  //     let ARMList = data.map((e, i) => ({
  //       label: e.email,
  //       value: e.id,
  //     }));
  //     setadminList(ARMList);
  //   } catch (error) {
  //     console.error(error);
  //   } finally {
  //     setLoading(false);
  //   }
  // };
  // useEffect(() => {
  //   getRMUSERList();
  // }, []);

 

  const { handleSubmit, handleChange, values, errors, touched } = useFormik({
    initialValues: {
      client_firstname: "",
      client_email: "",
      client_contactno: "",
      client_countrycode: "",
    },
    validationSchema,
    onSubmit: (values) => {
      saveFormValues(values);
    },
  });

  const getClientList = async (search) => {
    setLoading(true);
    console.log("search", search);
    const config = {
      method: "GET",
      url: CLIENT_LIST,
      headers: authHeader(),
      params: {
        index: currentpage,
        count: rowperpage,
        searchterm: search,
        rm_id: getRmID(),
      },
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      dispatch({
        type: CLIENT_LISTING,
        payload: data,
      });
      setList(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const searchClientList = async (search) => {
    setLoading(true);
    const config = {
      method: "GET",
      url: SEARCH_CLIENT_LIST,
      headers: authHeader(),
      params: {
        index: currentpage,
        count: rowperpage,
        searchterm: search,
        rm_id: getRmID(),
      },
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      setsearchListuser(data);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  const handleInputChange = (newValue) => {
    const inputlist = newValue.replace(/\W/g, "");
    getClientList(inputlist);
    return inputlist;
  };

  const handleInputChanges = (newValue) => {
    const inputlist = newValue.replace(/\W/g, "");
    searchClientList(inputlist);
    return inputlist;
  };
  useEffect(() => {}, [handleInputChange]);

  const getRmList = async (search) => {
    setLoading(true);
    const config = {
      method: "GET",
      url: IC_LIST,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      setRMList(data);
      console.log(data, "pppppppppppppppppppppppppppppp");
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getRmList();
  }, []);

  console.log(role,'roleSelectroleSelectroleSelect')
  useEffect(()=>{
    if(role=="rm"){
      setRoleSelect("arm")
    }
    if(role=="arm"){
      setRoleSelect("rm")
    }
    if(role=="admin"){
      setRoleSelect("admin")
    }
    if(role=="super_admin"){
      setRoleSelect("super_admin")
    }
    if(role=="ic"){
      setRoleSelect("ic")
    }
  },[role,roleSelect]);
  const getRM_ARM_NameList = async (search) => {
    const config = {
      method: "GET",
      url: RM_ARM_LIST_URL,
      headers: authHeader(),
      params: {
        role: roleSelect,
        index: currentpage,
        count: rowperpage,
        searchterm: search,
      },
    };
    try {
      setClientLoading(true);
      const response = await API(config);
      const { data = [] } = response.data;
      let clientList = data.map((e, i) => ({
        label: e.email,
        value: e.id,
      }));
      setClientOptions(clientList);
    } catch (error) {
      console.error(error);
    } finally {
      setClientLoading(false);
    }
  };

  useEffect(()=>{
    getRM_ARM_NameList();
  },[roleSelect]);


  return (
    <div className="">
      <div className="bg_red_img">
        <Container className="bg_red_ght">
          <Row className="justify-content-center bg_red_ght">
            <Col xl={6} md={12} className="align-self-center">
              <div className="board_white">
                <Row>
                  <Col sm="12">
                    <Form form="true" className="mt-3 form_space">
                      <div className="valuationCont mb-3">
                        <div className="valuationCheck">
                          <label className="form-check-label container_radio">
                            <input
                              className="form-check-input "
                              defaultChecked
                              type="radio"
                              name="radio"
                              value="1"
                              onChange={() => {
                                setselected("1");
                              }}
                            />
                            <span className="checktitle">
                              Create New Client
                            </span>
                            <span className="checkmark"></span>
                            <span className="checkborder"></span>
                          </label>
                        </div>
                        <div className="valuationCheck ml-sm-3">
                          <label className="form-check-label container_radio">
                            <input
                              className="form-check-input "
                              type="radio"
                              name="radio"
                              value="2"
                              onChange={() => {
                                setselected("2");
                              }}
                            />
                            <span className="checktitle">
                              Select Existing Client
                            </span>
                            <span className="checkmark"></span>
                            <span className="checkborder"></span>
                          </label>
                        </div>
                      </div>
                      {selected == "1" && (
                        <>
                          <Form
                            form="true"
                            onSubmit={handleSubmit}
                            className="mt-3 form_space"
                          >
                            <FormGroup>
                              <Input
                                type="text"
                                name="client_firstname"
                                id="client_firstname"
                                placeholder="Enter client name"
                                invalid={
                                  errors.client_firstname &&
                                  touched.client_firstname
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                                value={values.client_firstname}
                              />
                              <FormFeedback>
                                {console.log()}
                                {errors.client_firstname &&
                                touched.client_firstname
                                  ? errors.client_firstname
                                  : ""}
                              </FormFeedback>
                            </FormGroup>

                            <FormGroup>
                              <Input
                                type="client_email"
                                name="client_email"
                                id="client_email"
                                placeholder="Client email id"
                                invalid={
                                  errors.client_email && touched.client_email
                                    ? true
                                    : false
                                }
                                onChange={handleChange}
                                values={values.client_email}
                              />
                              <FormFeedback>
                                {errors.client_email && touched.client_email
                                  ? errors.client_email
                                  : ""}
                              </FormFeedback>
                            </FormGroup>

                            <FormGroup>
                              <PhoneInput
                                name="client_contactno"
                                id="client_contactno"
                                country={"sg"}
                                onChange={phoneNumberChange}
                                value={contactNumber}
                              />

                              <FormFeedback
                                style={{
                                  display:
                                    errors.client_contactno &&
                                    touched.client_contactno
                                      ? "block"
                                      : "none",
                                }}
                              >
                                {errors.client_contactno &&
                                touched.client_contactno
                                  ? errors.client_contactno
                                  : ""}
                              </FormFeedback>
                            </FormGroup>
                            <FormGroup>
                              <Input
                                type="text"
                                name="text"
                                id="text"
                                placeholder="Enter tags"
                              />
                            </FormGroup>

                            <FormGroup>
                              <label for="exampleFormControlSelect1">
                                Assign Investment Consultant (Optional)
                              </label>
                              {console.log(RMlist, "RMlistRMlistRMlistRMlist")}
                              <select
                                class="form-control"
                                id="exampleFormControlSelect1" 
                                onChange={(e)=>{
                                  setICvalue(e.target.value)
                                }}>
                              
                                <option>Choose investment consultant </option>
                                {RMlist?.map((rm) => {
                                  return <option value={rm.id}>{rm.email}</option>;
                                })}
                              </select>
                            </FormGroup>
                            <FormGroup>
                              {/* {console.log(role,'rolerolerolerolerolerolerolerolerole')} */}
                             {permission.user_permission == "arm" &&
                             <label for="exampleFormControlSelect2">
                                RM User
                              </label>
                              }
                              {permission.user_permission == "rm" &&
                             <label for="exampleFormControlSelect2">
                                ARM User
                              </label>
                              }
                              {console.log(RMlist, "RMlistRMlistRMlistRMlist")}
                              
                              <Select
                                class="form-control"
                                id="exampleFormControlSelect2"
                                options={clientOptions}
                                value={Value}
                                onChange={(e)=>{ 
                                  setValue(e);
                                  //handleChange(e)
                                }}
                              >
                                {console.log(Value,'SelectSelectSelectSelectSelect')}
                                {/* <option>Choose</option>
                                {rmuserList?.map((ev) => {
                                  return <option value={ev.user_id}>{ev.user_name}</option>;
                                })} */}
                                
                              </Select>
                            </FormGroup>
                            {/* {selectedRole == "arm" && (
                        <FormGroup>
                          <Label for="rm">RM User List</Label>
                          <Select
                            name="rm"
                            options={clientOptions}
                            isMulti
                            // defaultValue={{ label:selectedRM,value:"10" }}
                            onChange={(e) => {
                              setopt(e);
                              setinputRMlist(e);
                            handleMultiSelectChangeRM();
                              }}
                            className="select_rad"
                            onInputChange={handleInputRMChanges}
                            value={selectedRM}
                          />
                        </FormGroup>
                      )} */}
                                  {console.log(list,'cccccccccccccccccccccccccccccccc')}
                            {/* <form>
                              <label className="d-block">
                                Preferred Communication
                              </label>
                              <div className="form-group checked d-inline-block">
                                <input type="checkbox" id="html" />
                                <label className="bg_email" for="html">
                                  Email
                                </label>
                              </div>
                              <div className="form-group checked d-inline-block">
                                <input type="checkbox" id="css" />
                                <label className="bg_email ml-3" for="css">
                                  WhatsApp
                                </label>
                              </div>
                            </form> */}
                            <Button
                              type="submit"
                              className="d-block pl-5 pr-5 w-100 pt-2 pb-2 "
                              color="primary"
                            >
                              {loading && <CustomLoader loading={loading} />}
                              Select & Continue
                            </Button>
                          </Form>
                        </>
                      )}

                      {selected == "2" && (
                        <>
                          <FormGroup>
                            <Select
                              getOptionValue={(option) =>
                                option.client_firstname
                              }
                              getOptionLabel={(option) =>
                                option.client_firstname
                              }
                              onChange={(e) => {
                                setopt(e);
                                setinputlist(e.value);
                              }}
                              className="select_rad"
                              options={searchListuser}
                              onInputChange={handleInputChanges}
                            />
                          </FormGroup>
                          {opt != "" && (
                            <FormGroup>
                              <div className="red_relative mt-4">
                                <Input
                                  className="inr"
                                  type="text"
                                  value={opt.client_name}
                                  id="client_firstname"
                                />
                                <TiTick className="icon_c43"></TiTick>
                              </div>
                            </FormGroup>
                          )}
                          <Link
                            className="cus-link"
                            to={`/List-portfolio/${opt.client_id}`}
                          >
                            <Button
                              type="submit"
                              className="d-block w-100 pt-2 pb-2 btn_cont mt-4"
                              color="primary"
                            >
                              Select & Continue
                            </Button>
                          </Link>
                        </>
                      )}

                      <div className="text-right mt-3">
                        <Link className="cus-link" to={`/`}>
                          <a className="link_view">
                            Client Management
                            <span>
                              <FaArrowRight />
                            </span>
                          </a>
                        </Link>
                      </div>
                    </Form>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
