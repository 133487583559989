import React, { useState, Fragment, useEffect } from "react";
import { Row, Col, Button, Form, FormGroup, Label, Input } from "reactstrap";
import API from "../redux/API";
import { useFormik } from "formik";

import * as Yup from "yup";
import { CLIENT_LISTING_URL,PERMISSION, RM_EDIT_URL, USER_DETAILS } from "../common/urls";
import {
  authHeader,
  getAlert,
  getAlertToast,
  getDisplayName,
  getMail,
  getProfileImage,
  getRmID,
  getRole,
} from "../common/Mainfunctions";
import CustomLoader from "../common/CustomLoader";
import FormFeedback from "reactstrap/lib/FormFeedback";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";

import Swal from "sweetalert2";
export default function UserDetails() {
  const [loading, setLoading] = useState(false);
  const [clientOptions, setClientOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState([]);

  const [clientLoading, setClientLoading] = useState(false);
  const [permission, setpermission] = useState("");
  const [image, setImage] = useState();
  const [formData, setFormData] = useState({
    role: getRole(),
    profile_picture: getProfileImage(),
    is_image_update: "no",
  });
  const validationSchema = Yup.object({
    role: Yup.string().trim().required("Role is required"),
  });
  console.log(formData, "Check");
  const { handleSubmit, handleChange, handleBlur, values, errors, touched } =
    useFormik({
      initialValues: formData,
      validationSchema,
      onSubmit: (values) => {
        UpdateUserDetails(values);
      },
    });
  const handleMultiSelectChange = (selectedOptions, e) => {
    setSelectedOption(selectedOptions);
  };
  const RMDetails = async () => {
    const config = {
      method: "GET",
      url: RM_EDIT_URL + getRmID(),
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data } = response.data;
      const { client = [] } = data;
      let selclient = [];
      client.map((record) => {
        selclient.push({ label: record.client_name, value: record.client_id });
      });
      setSelectedOption(selclient);

      // setClient(selclient);
    } catch (error) {
      console.error(error);
    }
  };
  const getClientNameList = async () => {
    const config = {
      method: "GET",
      url: CLIENT_LISTING_URL,
      headers: authHeader(),
    };
    try {
      setClientLoading(true);
      const response = await API(config);
      const { data = [] } = response.data;
      let clientList = data.map((e, i) => ({
        label: e.client_name,
        value: e.client_id,
      }));
      setClientOptions(clientList);
    } catch (error) {
      console.error(error);
    } finally {
      setClientLoading(false);
    }
  };
  useEffect(() => {
    getClientNameList();
    RMDetails();
  }, []);
  const UpdateUserDetails = async (values) => {
    const { role, profile_picture, is_image_update } = formData;
    let selOptions =
      selectedOption &&
      selectedOption.map((option) => {
        return option.value;
      });
    values.clients = selOptions.join(",");
    const fileData = new FormData();
    fileData.append("role", values.role);

    fileData.append("is_image_update", is_image_update);
    if (is_image_update == "yes") {
      fileData.append("profile_picture", profile_picture);
    }
    //  else {
    //   fileData.append("profile_picture", "");
    // }

    fileData.append("clients", values.clients);
    console.log(fileData, "filedata");
    setLoading(true);
    const config = {
      method: "POST",
      url: USER_DETAILS + getRmID(),
      headers: authHeader(),
      data: fileData,
      "content-type": "multipart/form-data",
    };

    try {
      const response = await API(config);
      const { status, uploadfile } = response.data;
      console.log(response, "ImageImageImage");
      if (status == "Success") {
        localStorage.setItem("PROFILE_IMAGE", uploadfile);
        localStorage.setItem("ROLE", values.role);
        Swal.fire(getAlertToast("Success", "Profile Updated Successfully"));
      }
    } catch (error) {
      Swal.fire(getAlert("Error", "Profile Update Failed!"));

      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const getpermission = async () => {
    const config = {
      method: "GET",
      url: PERMISSION + getRmID(),
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      setpermission(response.data.data);
          console.log(response.data.data,'response.data.dataresponse.data.data')
      //Swal.fire(getAlertToast("Success", "User shared Successfully"));
    } catch (error) {
      let { message = "" } = error;
      console.log(message);
    } finally {
      //setLoading(false);
    }
  };
  useEffect(() => {
    getpermission();
  }, [getRmID]);
  const choosenImage = (event) => {
    setFormData({
      ...formData,
      profile_picture: event.target.files[0],
      is_image_update: "yes",
    });
  };
  useEffect(() => {
    console.log(
      formData.profile_picture,
      "222222222222222222222222222222222222"
    );
  }, [formData]);
  // console.log();
  return (
    <Fragment>
      <Form form="true" onSubmit={handleSubmit}>
        <Row>
          <Col sm="12">
            <Row>
              <Col md="12" className="mb-3">
                <img class="update-avatar d-block" src={getProfileImage()} />
                {/* <Button className="update-avatar-btn"> */}
                {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M493.25 56.26l-37.51-37.51C443.25 6.25 426.87 0 410.49 0s-32.76 6.25-45.26 18.74L12.85 371.12.15 485.34C-1.45 499.72 9.88 512 23.95 512c.89 0 1.78-.05 2.69-.15l114.14-12.61 352.48-352.48c24.99-24.99 24.99-65.51-.01-90.5zM126.09 468.68l-93.03 10.31 10.36-93.17 263.89-263.89 82.77 82.77-263.99 263.98zm344.54-344.54l-57.93 57.93-82.77-82.77 57.93-57.93c6.04-6.04 14.08-9.37 22.63-9.37 8.55 0 16.58 3.33 22.63 9.37l37.51 37.51c12.47 12.48 12.47 32.78 0 45.26z" />
                  </svg> */}
                <Input
                  // id="upload"
                  class="profile_picture__input"
                  type="file"
                  name="profile_picture"
                  id="profile_picture"
                  accept=".png, .jpg"
                  onChange={choosenImage}
                  values={values.profile_picture}
                />
                {/* </Button> */}
              </Col>

              <Col md="6">
                <FormGroup>
                  <Label for=""> Name</Label>
                  <Input
                    type="text"
                    name=""
                    id=""
                    // placeholder="Enter first name"
                    value={getDisplayName()}
                    disabled={true}
                  />
                </FormGroup>
              </Col>
              <Col md="6">
                <FormGroup>
                  <Label for="">Mail</Label>
                  <Input
                    type="text"
                    name="email"
                    id=""
                    // placeholder="Enter Last name"
                    value={getMail()}
                    disabled={true}
                  />
                </FormGroup>
              </Col>

              <Col md="6">
               
              {permission.user_permission !="arm"
               && permission.user_permission !="ic"  && 
              <FormGroup>
                  <Label for="role">Role</Label>
                  <Input
                    type="select"
                    name="role"
                    invalid={errors.role && touched.role ? true : false}
                    onChange={handleChange}
                    value={values.role}
                  >
                    <option value="">Select Role</option>
                    <option value="admin">Admin</option>
                    <option value="superadmin">Super Admin</option>
                    <option value="rm">RM</option>
                    <option value="arm">ARM</option>
                    <option value="ic">IC</option>
                    
                  </Input>
                  <FormFeedback>
                    {errors.role && touched.role ? errors.role : ""}
                  </FormFeedback>
                </FormGroup>}
              </Col>
              <Col>
                {values.role == "rm" && (
                  <FormGroup>
                    <Label for="client">Client</Label>
                    <ReactMultiSelectCheckboxes
                      name="clientMultiselect"
                      options={clientOptions}
                      width="100%"
                      onChange={handleMultiSelectChange}
                      value={selectedOption}
                    />
                  </FormGroup>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
        <Button
          color="primary"
          className="d-block m-auto"
          // onClick={UpdateUserDetails}
        >
          {loading && <CustomLoader loading={loading} />}
          Update
        </Button>
      </Form>
    </Fragment>
  );
}
