export const CORS_BYPASS = "https://cors-anywhere.herokuapp.com/";
export const BASE_API_URL =
  "https://dev-navigator-api.lighthouse-canton.com/api/";
//export const BASE_API_URL = "https://navigator-api.lighthouse-canton.com/api/";
//login
export const LOGIN_URL = BASE_API_URL + "login";
export const SSO_LOGIN_URL = BASE_API_URL + "login/sso";

//client
export const CLIENT_LIST = BASE_API_URL + "client/list";
export const SEARCH_CLIENT_LIST = BASE_API_URL + "client/searchclient";
export const RM_LIST = BASE_API_URL + "user/rmlist";
export const IC_LIST = BASE_API_URL + "user/iclist";
export const GET_CLIENT_PORTFOLIOS = BASE_API_URL + "client/portfolio/";
export const CREATE_CLIENT = BASE_API_URL + "client/create";
export const CLIENT_LIST_ALL = BASE_API_URL + "client/all";
export const CLIENT_LISTING_URL = BASE_API_URL + "client/total/list";
export const ASSIGN_CLIENT_URL = BASE_API_URL + "client/moveto";
export const ASSIGN_IC = BASE_API_URL + "client/assignic";
export const PORTFOLIO_VERSION = BASE_API_URL + "client/portfolioversion";

export const CLIENT_DELETE_URL = BASE_API_URL + "client/delete/";
export const CLIENT_NOTIFICATION = BASE_API_URL + "notifications/";
export const NOTIFICATION_STATUS = BASE_API_URL + "notifications/read/";
export const SHARE_PORTFOLIO = BASE_API_URL + "shareportfolio?portfolio_id=";
export const CLIENT_INFO = BASE_API_URL + "client/getinfo";
export const SAVE_ALLOCATION = BASE_API_URL + "client/saveallocation";
export const PIE_CHART = BASE_API_URL + "client/piechart";
export const PIE_CHART_COMP = BASE_API_URL + "client/piechartfilter";
export const PERMISSION = BASE_API_URL + "permission?userid=";

//summary
export const RISK_SUMMARY_URL = BASE_API_URL + "risksummary";
export const PERFORMANCE_SUMMARY_URL = BASE_API_URL + "performancesummary";
export const ASSET_SUMMARY_URL = BASE_API_URL + "assetsummary";
export const EXPORT_EXCEL_URL = BASE_API_URL + "export/excel";

//portfolio
export const REGION_LIST = BASE_API_URL + "region";
export const PORTFOLIO_DELETE = BASE_API_URL + "portfolio/delete/";
export const EXPOSURE_LIST = BASE_API_URL + "exposure";
export const COSTOFBORROW_LIST = BASE_API_URL + "costofborrow";
export const PORTFOLIO_CREATE = BASE_API_URL + "portfolio/create";
export const PORTFOLIO_EDIT_URL = BASE_API_URL + "portfolio/edit";
export const PORTFOLIO_UPDATE_URL = BASE_API_URL + "portfolio/update";
export const PORTFOLIO_GET = BASE_API_URL + "get_portfolio/";
export const GET_CASH_FLOW = BASE_API_URL + "get_cash_flow_threshold/USD";

//Asset Management

export const CREATE_INDEX_URL = BASE_API_URL + "createindex/list";
export const NEW_ASSET_URL = BASE_API_URL + "asset/create";
export const ASSET_MANAGEMENT_URL = BASE_API_URL + "asset/list";
export const ASSET_DELETE_URL = BASE_API_URL + "asset/delete";
export const ASSET_BULKUPLOAD_URL = BASE_API_URL + "asset/bulkupload";
export const RECOMMENDASSET_URL = BASE_API_URL + "recommend_assets/";
export const ASSET_LIST = BASE_API_URL + "remaining_assets/";
export const UPDATE_ASSET_STATUS = BASE_API_URL + "update_asset_status";
export const RESET_MODIFYALLOCATION = BASE_API_URL + "resetmodifyallocation";
export const SAVE_MODIFYALLOCATION = BASE_API_URL + "saveallocationgenxml";
export const BOND_INDEX = BASE_API_URL + "bondindex/list";
export const BOND_DELETE_PATH = BASE_API_URL + "bonds/delete";
export const BOND_BULK_UPLOAD_URL = BASE_API_URL + "bonds/bulkupload";
export const STOCK_FUNDS_URL = BASE_API_URL + "bondstockfunds";

//User Management
export const RM_LIST_URL = BASE_API_URL + "rm/list";
export const RM_ARM_LIST_URL = BASE_API_URL + "user/list_with_searchbyrole?";
export const RM_USER_URL = BASE_API_URL + "user/rm_armlist?user_id=";
export const ASSIGN_ARM_URL = BASE_API_URL + "client/assignarm";
export const ASSIGN_RM_URL = BASE_API_URL + "client/assignrm";
export const ARM_LIST_URL = BASE_API_URL + "arm/rmlist?user_id=";
export const RM_USER_CREATE = BASE_API_URL + "rm/create";
export const RM_DELETE_URL = BASE_API_URL + "rm/delete";
export const USER_LIST_URL = BASE_API_URL + "user/list";
export const RM_EDIT_URL = BASE_API_URL + "rm/edit/";
export const USER_DETAILS = BASE_API_URL + "rm/update/";
export const CHANGE_PASSWORD = BASE_API_URL + "rm/change/password/";
export const PORTFOLIO_ASSIGN_URL = BASE_API_URL + "assignedto";
export const PORTFOLIO_ACCEPT_URL = BASE_API_URL + "excelstatus";
export const ADMIN_VARIABLE_STORE_URL = BASE_API_URL + "admin/variable/store";
export const ADMIN_VARIABLES_LIST_URL = BASE_API_URL + "admin/variable/list";

//Benchmarks
export const BENCHMARK_LIST_URL = BASE_API_URL + "benchmark/list";
export const BENCHMARK_ADD_URL = BASE_API_URL + "benchmark/store";
export const BENCHMARK_FULL_LIST_URL = BASE_API_URL + "benchmark/data";
export const BENCHMARK_STATUS_URL = BASE_API_URL + "benchmark/status";
export const CREATE_GROUP = BASE_API_URL + "bondindex/create";
