import React, { useState, Fragment, useEffect, useFormik } from "react";
import Sidebar from "../layout/Sidebar";
import {
  Table,
  Container,
  Card,
  CardTitle,
  Button,
  Row,
  Col,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Modal,
  UncontrolledDropdown,
  Dropdown,
} from "reactstrap";
import CustomTableHeader from "../common/CustomTableHeader";

import { ASSET_MANAGEMENT_URL, ASSET_DELETE_URL } from "../common/urls";
import { AiOutlinePlusCircle, AiOutlineBell } from "react-icons/ai";
import NumberFormat from "react-number-format";
import {
  authHeader,
  getAlertToast,
  numToPercentage,
} from "../common/Mainfunctions";
import API from "../redux/API";
import { FormGroup } from "@material-ui/core";
import CustomTableLoader from "../common/CustomTableLoader";
import CustomEmptyTableRecord from "../common/CustomEmptyTableRecord";
import Pagination from "react-js-pagination";
import AddNewAsset from "../Asset/AddNewAsset";
import BulkUpload from "../Asset/BulkUpload";
import { FaEye, FaTrash } from "react-icons/fa";
import swal from "sweetalert2";
import Swal from "sweetalert2";
import ViewAsset from "./ViewAsset";
import { getAlert } from "../common/Mainfunctions";

export default function AssetsManagement(props) {
  const [bulkUploadModal, setBulkUploadModal] = useState(false);
  const [addModal, setAddModal] = useState(false);
  const [popup, setPopUp] = useState(false);
  const [viewModal, setViewModal] = useState(false);
  const [assetlist, setAssetList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [rowperpage, setRowPerPage] = useState(10);
  const [totalrecords, setTotalRecords] = useState(0);
  const [currentpage, setCurrentPage] = useState(0);
  const [assetId, setAssetId] = useState(0);
  const [selectAsset, setSelectedAsset] = useState([]);
  const addToggle = () => setAddModal(!addModal);
  const bulkUploadToggle = () => setBulkUploadModal(!bulkUploadModal);
  const viewToggle = () => setViewModal(!viewModal);
  const [modals, setmodals] = useState(false);
  const [isEventSelected] = useState();
  const { toggle, successCallBack } = props;
  const [indexName, setIndexName] = useState("");
  const [indexMaxWeight, setIndexMaxWeight] = useState(0);
  const [indexexpectedreturns, setIndexExpectedReturns] = useState("");

  const addSuccessCallBack = () => {
    addToggle();
    getAssetManagementList();
  };
  const [selectedfund, setselectedfund] = useState([]);

  const bulkUploadSuccessCallBack = () => {
    bulkUploadToggle();
    getAssetManagementList();
  };
  const viewSuccessCallBack = () => {
    viewToggle();
    getAssetManagementList();
  };

  const tableColumns = [
    { title: "S.No" },
    { title: "" },
    { title: "Fund Name" },
    { title: "Asset Class" },
    { title: "Strategy" },
    { title: "Geographical Focus" },
    { title: "Fund Size (million)" },
    { title: "Inception Date" },
    { title: "Currency" },
    { title: "Status" },
    { title: "Perfomance Fee" },
    { title: "Management Fee" },
    { title: "Minimum Investment" },
    { title: "Auditor" },
    { title: "Custodian" },
    { title: "Administrator" },
    { title: "Notice Period" },
    { title: "Lock Up Period (Months)" },
    { title: "Redemption Frequency" },
    { title: "Expected Return" },
    { title: "Max Weightage" },
    { title: "Cash Flow Yield" },
    { title: "Asset Industry Sector" },
    { title: "Asset Upside  Potential" },
    { title: "Last Refreshed" },
    { title: "Actions" },
  ];

  const getAssetManagementList = async () => {
    console.log(currentpage, "wwwwww");
    const config = {
      method: "GET",
      url: ASSET_MANAGEMENT_URL,
      headers: authHeader(),
      params: {
        index: currentpage,
        count: rowperpage,
      },
    };
    try {
      const response = await API(config);
      const { data = [], totalrecords } = response.data;
      setAssetList(data);
      setTotalRecords(totalrecords);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAssetManagementList();
  }, [currentpage]);

  const onPageChange = (page) => {
    setCurrentPage(page - 1);
  };
  const handleSelectEvent = (selectedid) => {
    console.log(selectedfund, selectedid, "lllllllllll");
    if (!selectedfund.includes(selectedid)) {
      setselectedfund((prevSelected) => [...prevSelected, selectedid]);
    } else {
      setselectedfund((prevSelected) =>
        prevSelected.filter((id) => id !== selectedid)
      );
    }
  };
  const deleteAsset = async (asset_id) => {
    const config = {
      method: "DELETE",
      url: ASSET_DELETE_URL + "/" + asset_id,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { status } = response.data;
      if (status === "success") {
        getAssetManagementList();
        Swal.fire(getAlertToast("Success", "Asset deleted Successfully"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };
  const viewRecord = (event) => {
    let id = parseInt(event.currentTarget.dataset.id);
    setAssetId(id);
    viewToggle();
    let filteredOption = assetlist.find((element) => element.id === id);

    setSelectedAsset(filteredOption);
  };
  const onClickDelete = (e) => {
    e.stopPropagation();
    let asset_id = parseInt(e.currentTarget.dataset.id);
    swal
      .fire({
        title: "Are you sure?",
        text: "Do you want to delete this asset?",
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      })
      .then((result) => {
        if (result.value) {
          deleteAsset(asset_id);
        }
      });
  };

  return (
    <div className="bg-white">
      <div className="sidebarCont bg_26">
        <Sidebar />
      </div>
      <div className="contentWrapper">
        <div className="bg_f2 pt-3 pb-3 pl-3 pr-3">
          <Row>
            <Col sm={6}>
              <div className="btn-right"></div>
            </Col>
            <Col sm={6} className="text-right">
              <UncontrolledDropdown
                setActiveFromChild
                className="d-inline-block"
              >
                <DropdownToggle tag="a" className="nav-link color_ac" caret>
                  <img
                    className="avatar"
                    src="https://images.unsplash.com/photo-1530645298377-82c8416d3f90?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                  />
                  <span>Solstium</span>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem> Logout </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Col>
          </Row>
        </div>
        <Container fluid className="">
          <Row>
            <Col sm={12}></Col>
            <Col sm={12}></Col>
          </Row>
          <Card className="min-screenheight st_card">
            <CardTitle className="p-0 mt-4 flex-container">
              <span className="list_cli">Assets Management</span>

              <div className="btn-right odr_1">
                <Button
                  onClick={bulkUploadToggle}
                  color="primary"
                  className="mb-2"
                >
                  Bulk Upload
                </Button>

                <Button onClick={addToggle} color="primary" className="mb-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16.867"
                    height="16.867"
                    viewBox="0 0 16.867 16.867"
                  >
                    <g
                      id="Icon_feather-plus"
                      data-name="Icon feather-plus"
                      transform="translate(1.5 1.5)"
                    >
                      <path
                        id="Path_34"
                        data-name="Path 34"
                        d="M18,7.5V21.367"
                        transform="translate(-11.067 -7.5)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                      />
                      <path
                        id="Path_35"
                        data-name="Path 35"
                        d="M7.5,18H21.367"
                        transform="translate(-7.5 -11.067)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                      />
                    </g>
                  </svg>
                  Add New Assets
                </Button>
              </div>
            </CardTitle>
            <Table hover responsive>
              <tr>
                <Table hover responsive>
                  <CustomTableHeader tableColumns={tableColumns} />
                  <tbody>
                    {!loading ? (
                      <Fragment>
                        {assetlist.length > 0 ? (
                          assetlist.map((assetSet, index) => {
                            console.log(assetSet, "wwwwwwwww");
                            //const isEventSelected = selectedfund.includes(record.bond_id);
                            const {
                              id,
                              asset_fundname,
                              asset_class,
                              asset_strategy,
                              asset_geo_focus,
                              asset_fund_size,
                              asset_inceptiondate,
                              asset_currency,
                              asset_status,
                              asset_auditor,
                              asset_custodian,
                              asset_administrator,
                              asset_noticeperiod,
                              asset_lockupperiod,
                              asset_management_fee,
                              asset_minimum_investment,
                              asset_performance_fee,
                              asset_expected_returns,
                              cash_flow_yield,
                              asset_industry_sector,
                              asset_upside_potential,
                              asset_createdon,
                              max_weightage,
                              asset_redemptionperiod,
                            } = assetSet;
                            return (
                              <tr key={index}>
                                <td>
                                  {rowperpage * currentpage + (index + 1)}
                                </td>
                                <td>
                                  <Button
                                    data-id={id}
                                    color=""
                                    size="sm"
                                    className="actionBtn"
                                    onClick={viewRecord}
                                  >
                                    <FaEye></FaEye>
                                  </Button></td>
                                <td>{asset_fundname}</td>
                                <td>{asset_class}</td>
                                <td>{asset_strategy}</td>
                                <td>{asset_geo_focus}</td>
                                <td>
                                  <NumberFormat
                                    thousandSeparator={true}
                                    value={asset_fund_size}
                                    displayType={"text"}
                                  />
                                </td>
                                <td>{asset_inceptiondate}</td>
                                <td>{asset_currency}</td>
                                <td>{asset_status}</td>
                                <td>
                                  {numToPercentage(asset_performance_fee)}
                                </td>
                                <td>{numToPercentage(asset_management_fee)}</td>
                                <td>
                                  <NumberFormat
                                    thousandSeparator={true}
                                    value={asset_minimum_investment}
                                    displayType={"text"}
                                  />
                                </td>

                                <td>{asset_auditor}</td>
                                <td>{asset_custodian}</td>
                                <td>{asset_administrator}</td>
                                <td>{asset_noticeperiod}</td>
                                <td>{asset_lockupperiod}</td>
                                <td>{asset_redemptionperiod}</td>
                                <td>
                                  {numToPercentage(asset_expected_returns)}
                                </td>
                                <td>{numToPercentage(max_weightage)}</td>
                                <td>{numToPercentage(cash_flow_yield)}</td>
                                <td>{asset_industry_sector}</td>
                                <td>
                                  {numToPercentage(asset_upside_potential)}
                                </td>
                                <td style={{ whiteSpace: "pre" }}>
                                  {asset_createdon}
                                </td>
                                <td style={{ display: "flex" }}>
                                  {/* <div> */}
                                  

                                  <Button
                                    data-id={id}
                                    color=""
                                    size="sm"
                                    className="actionBtn"
                                    onClick={onClickDelete}
                                  >
                                    <FaTrash></FaTrash>
                                  </Button>

                                  {/* </div> */}
                                </td>
                              </tr>
                            );
                          })
                        ) : (
                          <CustomEmptyTableRecord
                            columnsCount={tableColumns.length}
                          />
                        )}
                      </Fragment>
                    ) : (
                      <CustomTableLoader columnsCount={tableColumns.length} />
                    )}
                  </tbody>
                </Table>
              </tr>
            </Table>
            <div className="d-flex flex-row py-4 justify-content-end">
              <Pagination
                itemClass="page-item"
                linkClass="page-link"
                activePage={currentpage + 1}
                itemsCountPerPage={rowperpage}
                totalItemsCount={totalrecords}
                onChange={onPageChange}
              />
            </div>
          </Card>
        </Container>
        {addModal && (
          <AddNewAsset
            modal={addModal}
            toggle={addToggle}
            successCallBack={addSuccessCallBack}
          />
        )}
      </div>
      {bulkUploadModal && (
        <BulkUpload
          modal={bulkUploadModal}
          toggle={bulkUploadToggle}
          successCallBack={bulkUploadSuccessCallBack}
        />
      )}
      {viewModal && (
        <ViewAsset
          modal={viewModal}
          toggle={viewToggle}
          successCallBack={viewSuccessCallBack}
          id={assetId}
          selectAsset={selectAsset}
        />
      )}
    </div>
  );
}
