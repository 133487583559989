import React, { Fragment, useState, useEffect, useRef } from "react";
import {
  Table,
  Container,
  Card,
  CardTitle,
  Button,
  Input,
  InputGroup,
  UncontrolledDropdown,
  InputGroupAddon,
  Collapse,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import {
  ADD_CLIENT_ROUTE,
  PORTFOLIO_INPUTS_CREATE_ROUTE,
  SETTINGS_ROUTE,
} from "../common/RoutePaths";
import { useDispatch } from "react-redux";
import {
  authHeader,
  getAlert,
  getAlertToast,
  getRmID,
  getDisplayName,
  getProfileImage,
  getProfileName,
  getRole,
  logOutFun,
} from "../common/Mainfunctions";
import {
  FaSortDown,
  FaCaretRight,
  FaEye,
  FaEdit,
  FaAngleDown,
  FaUser,
  FaCheckCircle,
  FaTimesCircle,
  FaTrash,
  FaFileExcel,
  FaFilePdf,
} from "react-icons/fa";
import {
  BENCHMARK_STATUS_URL,
  CLIENT_DELETE_URL,
  CLIENT_LIST,
  PORTFOLIO_ASSIGN_URL,
  PORTFOLIO_ACCEPT_URL,
  GET_CLIENT_PORTFOLIOS,
  PORTFOLIO_DELETE,
  PORTFOLIO_GET,
  CLIENT_INFO,
} from "../common/urls";
import API from "../redux/API";
import CustomEmptyTableRecord from "../common/CustomEmptyTableRecord";
import Swal from "sweetalert2";
import swal from "sweetalert2";
import { Modal, ModalHeader, ModalBody, ModalFooter, Col } from "reactstrap";
import { Prompt, useHistory, useParams } from "react-router-dom";
import NumberFormat from "react-number-format";
import {
  Tooltip,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Dropdown,
} from "reactstrap";
export default function Portfolioversion(props) {

  const dispatch = useDispatch();
  const [token, settoken] = useState("");
  const [selectClientModal, setSelectClientModal] = useState(false);
  const selectClientToggle = () => setSelectClientModal(!selectClientModal);
  const [assignClientModal, setAssignClientModal] = useState(false);
  const assignClientToggle = () => setAssignClientModal(!assignClientModal);
  const [clist, setList] = useState([]);
  const [search, setSearch] = useState("");
  const [permission, setpermission] = useState("");
  const [selectedClient, setClientId] = useState(0);
  const [selectedportfolio, setPortfolioId] = useState(0);
  const [selectedRm, setSelectedRm] = useState(0);
  const [showloader, setShowloader] = useState(true);
  const [loading, setLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [selectedPorfolioId, setSelectedPortfolioId] = useState("");
  const [clientPortfolioList, setClientPortfolioList] = useState([]);
  const [versionportfolioerror, setversionPortfoliError] = useState("");
  const [sum, setsum] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("AUTH_TOKEN");
    settoken(token);
  }, []);

  const history = useHistory();
  // const [benchmarkStatusFlag, setBenchStatus] = useState();
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [tooltipOpen1, setTooltipOpen1] = useState(false);

  const toggle1 = () => setTooltipOpen1(!tooltipOpen1);

  const [tooltipOpen2, setTooltipOpen2] = useState(false);
  const [intervalTime, setIntervalTime] = useState(0);
  const [version, setversion] = useState(props.version);

  const toggle2 = () => setTooltipOpen2(!tooltipOpen2);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggleport = () => setDropdownOpen((dropdownOpen) => !dropdownOpen);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const toggleport1 = () => setDropdownOpen1((dropdownOpen1) => !dropdownOpen1);
  const [dropFlag, settingDropDown] = useState(false);
  const [modal, setModal] = useState(false);
  const [clientPortfolioLists, setClientPortfolioLists] = useState(0);
  const [porfolioI, setPortfolioI] = useState(props.id);
  const [clientdetails, setClientdetails] = useState({});
  const [isOpen3, setIsOpen3] = React.useState(false);
  const [childversionid, setChildversionid] = useState(0);

  const toggles3 = () => setIsOpen3(!isOpen3);

  const toggles = () => setModal(!modal);

  const setFlag = () => {
    settingDropDown(true);
  };
  const clienttableColumns = [
    { title: "S.No" },
    { title: "RM Name" },
    { title: "Client Name" },
    { title: "Email" },
    { title: "Phone Number" },
    { title: "Onboarded" },
    { title: "List of Portfolios" },
  ];
  const tablePortfolioColumns = [
    { title: "S.No" },
    { title: "Portfolio Name" },
    { title: "Currency" },
    { title: "Client Equity" },
    { title: "Leverage" },
    { title: "Date of Creation" },
    { title: "Status" },
    { title: "Actions" },
  ];

  const onHandleChange = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const toggleset = (event) => {
    const { cid } = event.currentTarget.dataset;
    if (parseInt(cid) === parseInt(selectedClient)) {
      setIsOpen(!isOpen);
    } else {
      setIsOpen(true);
    }
    setClientId(cid);
  };

  const toggleset1 = (event) => {
    const { pid } = event.currentTarget.dataset;

    //if (parseInt(pid) === parseInt(selectedportfolio)) {
    setIsOpen1(!isOpen1);
    //} else {
    //setIsOpen1(true);
    //}
    setPortfolioId(pid);
  };
  const onClearfun = (e) => {
    setSearch("");
  };
  const assignToClient = async (e) => {
    const config = {
      method: "PUT",
      url: PORTFOLIO_ASSIGN_URL + "/" + porfolioI,
      headers: authHeader(),
      data: { portfolio_user: selectedClient },
    };
    try {
      setLoading(true);
      const response = await API(config);
      const { status, data } = response.data;

      if (status === "Success") {
        Swal.fire(getAlertToast("success", data));
        submitCallBack();
      } else if (status === "Error") {
        Swal.fire(getAlert("error", "Failed to Update"));
        submitCallBack();
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getPortfolio();
    getclientdetails();
    //console.log(clientPortfolioLists,"tgggtttttlll")
  }, []);

  const onClickDelete = (portfolio_id) => {
    /// e.stopPropagation();
     //let asset_id = parseInt(e.currentTarget.dataset.id);
     swal
       .fire({
         title: "Are you sure?",
         text: "Do you want to delete this Portfolio Version?",
         type: "warning",
         showCancelButton: true,
         confirmButtonColor: "#3085d6",
         cancelButtonColor: "#d33",
         confirmButtonText: "Yes",
       })
       .then((result) => {
         if (result.value) {
          portfolio_delete(portfolio_id);
         }
       });
   };
 
   const portfolio_delete = async (portfolio_id) => {
     const config = {
       method: "GET",
       url: PORTFOLIO_DELETE + portfolio_id,
       headers: authHeader(),
     };
     try {
       const response = await API(config);
       let { status } = response.data;
       if (status == "success") {
        props.getPortfolioversion();
        props.getClientPortfolio();
         Swal.fire(getAlertToast("Success", "Portfolio Version Deleted Successfully"));
       } else {
         Swal.fire(getAlertToast("error", "Portfolio cannot Deleted"));
       }
     } catch (error) {
       let { message = "" } = error;
       console.log(message);
     } finally {
       setLoading(false);
     }
   };


  const submitCallBack = (e) => {
    selectClientToggle(false);
  };

  const assignSubmitCallBack = (e) => {
    assignClientToggle(false);
  };

  const assignToggle = (event) => {
    const portfolioId = event.currentTarget.dataset.portfolioid;
    const cid = event.currentTarget.dataset.cid;
    setSelectedPortfolioId(portfolioId);
    setClientId(cid);
    selectClientToggle();
  };

  const onClickAssignClient = (event) => {
    const rm_id = event.currentTarget.dataset.rmid;
    const cid = event.currentTarget.dataset.cid;
    setClientId(cid);
    setSelectedRm(rm_id);
    assignClientToggle();
  };

  const acceptPortfolio = async (portfolioID) => {
    const config = {
      method: "GET",
      url: PORTFOLIO_ACCEPT_URL + "/" + portfolioID + "/1",
      headers: authHeader(),
    };console.log(props ,'eeeeeeeeeeeeeeeee')
    try {
      const response = await API(config);
      let { status } = response.data;
      if (status == "success") {
        Swal.fire(getAlertToast("Success", "Portfolio accepted Successfully"));
      } else {
        Swal.fire(getAlertToast("error", "Portfolio cannot accept"));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
      props.getPortfolioversion();
      props.getClientPortfolio();
      props.setversionid(false);
    }
  };
  const getPortfolio = async () => {
    const config = {
      method: "GET",
      url: PORTFOLIO_GET + porfolioI,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      let { status, data } = response.data;
      setClientPortfolioLists(data.portfolio);

      console.log(data.portfolio.client.client_name, "client");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getPortfolio();
    //console.log(clientPortfolioLists,"tgggtttttlll")
  }, []);
  const getclientdetails = async () => {
    // const config = {
    //   method: "GET",
    //   url: CLIENT_INFO + "/" + porfolioI,
    //   headers: authHeader(),
    // };
    // try {
    //   const response = await API(config);
    //   let { status, data } = response.data;
    //   setClientdetails(data);
    // } catch (error) {
    //   console.log(error);
    // }
  };
  const getClientPortfolio = async () => {
    
    if (props.id == 0) return false;
    const config = {
      method: "GET",
      url: GET_CLIENT_PORTFOLIOS + props.id,
      headers: authHeader(),
    };
    try {
     // console.log(props.id, "tttttttttttttttttttttttttttttttt");
      const response = await API(config);

      let { status, data } = response.data;
      console.log(data, status, "datadata");
      if (status == "Success") {
        setClientPortfolioList(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setShowloader(false);
    }
  };

  const onClickAccept = (e) => {
    e.stopPropagation();
    let portfolioId = parseInt(e.currentTarget.dataset.portfolioid);
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to accept this portfolio? Other Version Will Be Deleted ! ",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        acceptPortfolio(portfolioId);
      }

    });
  };
  const deleteClient = async (event) => {
    let list = [];
    const clientId = event.currentTarget.dataset.cid;
    console.log(clist);
    list = clist.filter((option) => option.portfolio == "");
    console.log(list);
    const client = list.filter((record) => record.client_id == clientId);
    console.log(client);
    const config = {
      method: "DELETE",
      url: CLIENT_DELETE_URL + clientId,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data } = response.data;
      if (response.status == "error") {
        Swal.fire(getAlert("error", data));
      } else {
        Swal.fire(getAlertToast("Success", data));
      }

      /*if (client.length > 0) {
        const response = await API(config);
        Swal.fire(getAlertToast("Success", "Client deleted Successfully"));
        getClientList();
        console.log(response);
      } else {
        Swal.fire(getAlert("error", "Client has portfolio can't delete"));
      }*/
    } catch (error) {
      Swal.fire(getAlert("error", error));
    }
  };
  const benchmarkStatus = async () => {
    const config = {
      method: "GET",
      url: BENCHMARK_STATUS_URL,
      headers: authHeader(),
    };
    try {
      const response = await API(config);
      const { data } = response.data;
      console.log(data);
      if (data === "valid") {
        setSelectedPortfolioId("");
        selectClientToggle();
      } else {
        Swal.fire(getAlert("error", "Contact admin"));
      }
      // setBenchStatus(data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    setClientPortfolioList([]);
    getClientPortfolio();
    //clearInterval(intervalTime);
    //setIntervalTime(setInterval(() => getClientPortfolio(), 20000));
  }, [selectedClient]);
  //

  return (
    <>
      <tr key={`portfolio_key1_${props.index1}`}>
        <td>{version.portfolio_version}</td>  
        <td>{version.portfolio_name}</td>
        <td>{version.portfolio_currency}</td>
        <td>
          <NumberFormat
            thousandSeparator={true}
            value={version.portfolio_clientequity}
            displayType={"text"}
          />
        </td>
        <td>{version.portfolio_leverage}</td>
        <td>{version.portfolio_createdon}</td>

        {(() => {
          if (version.portfolio_status == "failed") {
            return (
              <td
                onClick={() => {
                  setversionPortfoliError(version.portfolio_error);
                  toggles();
                }}
                id={`errortooltip_${version.portfolio_id}`}
              >
                {/* <FaAngleDown
                  className="ps4"
                  onClick={() => {
                    //props.setchildversionid(version.portfolio_id);
                  }}
                ></FaAngleDown>
                <span className="rns">Version</span> */}
                {version.portfolio_status}
                {(() => {
                  if (version.exe_status == 0) {
                    return (
                      <UncontrolledTooltip
                        placement="right"
                        target={`errortooltip_${version.portfolio_id}`}
                      >
                        Success
                      </UncontrolledTooltip>
                    );
                  }
                })()}
                {(() => {
                  if (version.exe_status == 1) {
                    return (
                      <UncontrolledTooltip
                        placement="right"
                        target={`errortooltip_${version.portfolio_id}`}
                      >
                        Some error in parameter or during
                        import/export/optimisation
                      </UncontrolledTooltip>
                    );
                  }
                })()}
                {(() => {
                  if (version.exe_status == 2) {
                    return (
                      <UncontrolledTooltip
                        placement="right"
                        target={`errortooltip_${version.portfolio_id}`}
                      >
                        Aborted by user
                      </UncontrolledTooltip>
                    );
                  }
                })()}
                {(() => {
                  if (version.exe_status == 3) {
                    return (
                      <UncontrolledTooltip
                        placement="right"
                        target={`errortooltip_${version.portfolio_id}`}
                      >
                        Issue with db connection
                      </UncontrolledTooltip>
                    );
                  }
                })()}
                {(() => {
                  if (version.exe_status == 4) {
                    return (
                      <UncontrolledTooltip
                        placement="right"
                        target={`errortooltip_${version.portfolio_id}`}
                      >
                        Optimization failed 
                      </UncontrolledTooltip>
                    );
                  }
                })()}
                {(() => {
                  if (version.exe_status == 100) {
                    console.log(version.exe_status, "****************");
                    return (
                      <UncontrolledTooltip
                        placement="right"
                        target={`errortooltip_${version.portfolio_id}`}
                      >
                        Unexpected/unspecified error
                      </UncontrolledTooltip>
                    );
                  }
                })()}
              </td>
            );
          } else if (version.portfolio_status == "failed-previous-success") {
            return (
              <td
                id={`errortooltip_${version.portfolio_id}`}
                onClick={() => {
                  setversionPortfoliError(version.portfolio_error);
                  toggles();
                }}
              >
                failed 
                {(() => {
                  if (version.exe_status == 0) {
                    return (
                      <UncontrolledTooltip
                        placement="right"
                        target={`errortooltip_${version.portfolio_id}`}
                      >
                        Success
                      </UncontrolledTooltip>
                    );
                  }
                })()}
                {(() => {
                  if (version.exe_status == 1) {
                    return (
                      <UncontrolledTooltip
                        placement="right"
                        target={`errortooltip_${version.portfolio_id}`}
                      >
                        Some error in parameter or during
                        import/export/optimisation
                      </UncontrolledTooltip>
                    );
                  } else if (version.exe_status == 2) {
                    return (
                      <UncontrolledTooltip
                        placement="right"
                        target={`errortooltip_${version.portfolio_id}`}
                      >
                        Aborted by user
                      </UncontrolledTooltip>
                    );
                  } else if (version.exe_status == 3) {
                    return (
                      <UncontrolledTooltip
                        placement="right"
                        target={`errortooltip_${version.portfolio_id}`}
                      >
                        Issue with db connection
                      </UncontrolledTooltip>
                    );
                  } else if (version.exe_status == 4) {
                    return (
                      <UncontrolledTooltip
                        placement="right"
                        target={`errortooltip_${version.portfolio_id}`}
                      >
                        Optimization failed
                      </UncontrolledTooltip>
                    );
                  } else if (version.exe_status == 100) {
                    return (
                      <UncontrolledTooltip
                        placement="right"
                        target={`errortooltip_${version.portfolio_id}`}
                      >
                        Unexpected/unspecified error
                      </UncontrolledTooltip>
                    );
                  }
                })()}
              </td>
            );
          } else if (version.portfolio_status == "failed-previous-failed") {
            return (
              <td id={`errortooltip_${version.portfolio_id}`}>
                <div
                  onClick={() => {
                    setversionPortfoliError(version.portfolio_error);
                    toggles();
                  }}
                >
                  failed
                </div>
                {(() => {
                  if (version.exe_status == 0) {
                    return (
                      <UncontrolledTooltip
                        placement="right"
                        target={`errortooltip_${version.portfolio_id}`}
                      >
                        Success
                      </UncontrolledTooltip>
                    );
                  }
                })()}
                {(() => {
                  if (version.exe_status == 1) {
                    return (
                      <UncontrolledTooltip
                        placement="right"
                        target={`errortooltip_${version.portfolio_id}`}
                      >
                        Some error in parameter or during
                        import/export/optimisation
                      </UncontrolledTooltip>
                    );
                  } else if (version.exe_status == 2) {
                    return (
                      <UncontrolledTooltip
                        placement="right"
                        target={`errortooltip_${version.portfolio_id}`}
                      >
                        Aborted by user
                      </UncontrolledTooltip>
                    );
                  } else if (version.exe_status == 3) {
                    return (
                      <UncontrolledTooltip
                        placement="right"
                        target={`errortooltip_${version.portfolio_id}`}
                      >
                        Issue with db connection
                      </UncontrolledTooltip>
                    );
                  } else if (version.exe_status == 4) {
                    return (
                      <UncontrolledTooltip
                        placement="right"
                        target={`errortooltip_${version.portfolio_id}`}
                      >
                        Optimization failed
                      </UncontrolledTooltip>
                    );
                  } else if (version.exe_status == 100) {
                    return (
                      <UncontrolledTooltip
                        placement="right"
                        target={`errortooltip_${version.portfolio_id}`}
                      >
                        Unexpected/unspecified error
                      </UncontrolledTooltip>
                    );
                  }
                })()}
              </td>
            );
          } else if (version.portfolio_status == "processing") {
            return (
              <td>
                {version.portfolio_status}{" "}
                {version.queue > 0 && <small>(Queue # {version.queue})</small>}
              </td>
            );
          } else {
            return <td>{version.portfolio_status}</td>;
          }
        })()}
        <td className="table-btn-section">
         
          {(() => {
            if (version.portfolio_status == "success") {
              return (
                <>
                  <Fragment>
                    <Dropdown
                      // isOpen={`${dropdownOpen}+${
                      //   index + 1
                      // }`}
                      // toggle={`${toggleport}+${
                      //   index + 1
                      // }`}
                      isOpen={
                        isOpen1 &&
                        parseInt(selectedportfolio) ===
                          parseInt(version.portfolio_id)
                      }
                      size="sm"
                      color=""
                      data-pid={version.portfolio_id}
                      onClick={toggleset1}
                    >
                      <DropdownToggle
                        tag="span"
                        data-toggle="dropdown"
                        aria-expanded={dropdownOpen}
                        variant="success"
                      >
                        <>
                          <FaEdit></FaEdit>
                          <span className="pl-2 rns">Modify</span>
                        </>
                      </DropdownToggle>
                      
                      <DropdownMenu>
                        {/* <DropdownItem tag="a">
                          <Link
                            className="cus-link "
                            to={{
                              pathname:
                                PORTFOLIO_INPUTS_CREATE_ROUTE +
                                "/" +
                                version.portfolio_id,
                              state: {
                                leverageFlag: true,
                                constraintFlag: false,
                              },
                            }}
                          >
                            Add Leverage
                          </Link>
                        </DropdownItem> */}
                        <DropdownItem tag="a">
                          <Link
                            className="cus-link "
                            to={{
                              pathname:
                                PORTFOLIO_INPUTS_CREATE_ROUTE +
                                "/" +
                                version.portfolio_id,
                              state: {
                                leverageFlag: false,
                                constraintFlag: true,
                              },
                            }}
                          >
                            Modify Constraints
                          </Link>
                        </DropdownItem>
                        <DropdownItem tag="a">
                          <Link
                            className="cus-link "
                            to={{
                              pathname:
                                "/detail-portfolio/summary" +
                                "/" +
                                version.portfolio_id,
                            }}
                          >
                            Modify Allocation
                          </Link>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                    
                    {/* <Button
                      color=""
                      size="sm"
                      className="actionBtn"
                      data-portfolioid={version.portfolio_id}
                      onClick={() => {
                        setChildversionid(version.portfolio_id);
                      }}
                    ></Button> */}
                    <Button
                      color=""
                      size="sm"
                      className="actionBtn"
                      data-portfolioid={version.portfolio_id}
                    >
                      <Link
                        className="cus-link "
                        to={{
                          pathname:
                            "/detail-portfolio/summary/" + version.portfolio_id,
                        }}
                      >
                        <>
                          <FaEye></FaEye>
                          <span className="ml-2">View</span>
                        </>
                      </Link>
                    </Button>
                        {console.log(permission,'permissionpermissionpermission')}
                    {permission != "arm" && permission != "rm" &&permission != "ic" && (
                        <>                                                
                        <Button
                          color=""
                          size="sm"
                          className="actionBtn text-left"
                          onClick={()=>onClickDelete(version.portfolio_id)}
                        >
                          <>
                              <FaTrash className="mr-2"></FaTrash>
                              <span>Delete</span>
                            </>
                        </Button>
                        </> )}
                    {version.portfolio_excel != 1 && (
                      <>
                        <Button color="" size="sm" className="actionBtn">
                          <>
                            <FaFileExcel />
                            <a
                              target="_blank"
                              href={`https://navigator-api.lighthouse-canton.com/lighthouse_pdf/download.php?id=${version.portfolio_id}&token=${token}&draft=1`}
                            >
                              <span className="ml-2">Draft</span>
                            </a>
                          </>
                        </Button>
                        <Button
                          color=""
                          size="sm"
                          className="actionBtn"
                        ></Button>
                      </>
                    )}

                    {version.portfolio_excel == 1 && (
                      <>
                        <Button color="" size="sm" className="actionBtn">
                          <>
                            <FaFileExcel />
                            <span className="ml-2">View PDF</span>
                          </>
                        </Button>
                        <Button color="" size="sm" className="actionBtn">
                          <FaFilePdf />
                        </Button>
                      </>
                    )}

                    {version.portfolio_excel == 1 ? (
                      <Button color="" size="sm" className="actionBtn">
                        <FaCheckCircle
                          style={{
                            fill: "green",
                          }}
                        ></FaCheckCircle>
                      </Button>
                    ) : (
                      <Fragment>
                        {/* <Button color="" size="sm" className="actionBtn">
                          <FaTimesCircle></FaTimesCircle>
                        </Button> */}
                        <Button
                          color="primary"
                          size="sm"
                          className="actionBtn"
                          data-portfolioid={version.portfolio_id}
                          onClick={onClickAccept}
                        >
                          Accept
                        </Button>
                      </Fragment>
                    )}
                  </Fragment>
                </>
              );
            } else if (version.portfolio_status == "failed" || "failed-previous-success") {
              return (
                <>
                
                  <Dropdown
                    isOpen={dropdownOpen}
                    toggle={toggleport}
                    size="sm"
                    color=""
                  >
                    <DropdownToggle
                      tag="span"
                      data-toggle="dropdown"
                      aria-expanded={dropdownOpen}
                      variant="success"
                    >
                      <FaEdit></FaEdit>
                      <span className="pl-2 rns">Modifyyyyyyyy</span>
                    </DropdownToggle>
                    {permission != "arm" && permission != "rm" &&permission != "ic" && (
                        <>                                                
                        <Button
                          color=""
                          size="sm"
                          className="actionBtn text-left"
                          
                          onClick={()=>onClickDelete(version.portfolio_id)}
                        >
                            <>
                              <FaTrash className="mr-2"></FaTrash>
                              <span>Delete</span>
                            </>
                        </Button>
                        </>
                                  )}
                    <DropdownMenu>
                    
                      {/* <DropdownItem tag="a">
                        <Link
                          className="cus-link "
                          to={{
                            pathname:
                              PORTFOLIO_INPUTS_CREATE_ROUTE + "/" + props.id,
                            state: {
                              leverageFlag: true,
                              constraintFlag: false,
                            },
                          }}
                        >
                          Add Leverage
                        </Link>
                      </DropdownItem> */}

                      <DropdownItem tag="a">
                        <Link
                          className="cus-link "
                          to={{
                            pathname:
                              PORTFOLIO_INPUTS_CREATE_ROUTE + "/" + props.id,
                            state: {
                              leverageFlag: false,
                              constraintFlag: true,
                            },
                          }}
                        >
                          Modify Constraints
                        </Link>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </>
              );
            }
          })()}
          <Modal isOpen={modal} toggle={toggles} size="xl">
            <div className="modalwidth">
              <ModalHeader toggle={toggles}>Error Detail</ModalHeader>
              <ModalBody className="bodywidth">
                <pre style={{ padding: `10px` }}>{versionportfolioerror}</pre>
              </ModalBody>
              <ModalFooter className="footerwidth">
                <Button color="secondary" onClick={toggles}>
                  Cancel
                </Button>
              </ModalFooter>
            </div>
          </Modal>
        </td>
      </tr>
      {childversionid == version.portfolio_id && (
        <tr>
          <td colSpan="8" bgcolor="#f7f7f7">
            <table className="table">
              <thead>
                <th colSpan="8">Version</th>
              </thead>
              <tbody>
                {version.portfolio_version_set ? (
                  version.portfolio_version_set.map((childversion, index2) => {
                    const {
                      portfolio_id,
                      portfolio_name,
                      portfolio_clientequity,
                      portfolio_currency,
                      portfolio_createdon,
                      portfolio_leverage,
                      version_portfolio_status,
                      portfolio_excel,
                      portfolio_error,
                      exe_status,
                      queue,
                    } = childversion;

                    return (
                      <>
                        <Portfolioversion
                          setchildversionid={setChildversionid}
                          version={childversion}
                          id={portfolio_id}
                          index1={index2}
                        ></Portfolioversion>
                      </>
                    );
                  })
                ) : (
                  <CustomEmptyTableRecord
                    columnsCount={tablePortfolioColumns.length}
                  />
                )}
              </tbody>
            </table>
          </td>
        </tr>
      )}
    </>
  );
}
